import React, { Component } from 'react';
import {Row, Col } from 'reactstrap';
import {withFormik} from 'formik'
import yup from 'yup'
import './contact.css';

class ContactDisplayComponent extends Component {
constructor(props){
  super(props);
  this.state={
  };
}

  render() {
    return (
        <Row>
          <Col lg='12'>
          
          </Col>
        </Row>
    );
  }
}

export default ContactDisplayComponent;
