const resumeDownloadLinks = [
    {
        id: 0,
        type:"PDF",
        link:"https://drive.google.com/open?id=1vyHYe8c8ZezWsuF1V5wUW1kebyXLAEna",
    },
    {
        id: 1,
        type:"DOCX",
        link:"https://drive.google.com/open?id=1sJJFevKR1WmB-6lyWP2EQNPbYNdPdY6t",
    }
]

  export {resumeDownloadLinks};